<template>
    <section class="py-5" style="padding-top: 0rem !important;background: #9DB0B0;">
        <top-home/>
    </section>
    <section class="py-5 store-single-page">
        <div class="container pb-lg-4 bg-dark">
            <div class="row">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12" style="padding: 0;">
                            <div>
                                <div class="container" style="padding: 0;margin: 0;margin-top: 11px;">
                                    <div class="col-lg-12">
                                        <div class="osahan-card-body mt-3" v-for="game in gamesDetails" :key="game.id">
                                            <h3 class="mt-4 mb-3" style="text-align: right;"> {{ game.title_ar }}</h3>
                                        <p class="mt-4 mb-3" style="padding-top: 0%;text-align: right;text-justify: inter-word;font-size: 20px;">{{ game.discription_ar }}</p>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="position-sticky" v-for="game in gamesDetails" :key="game.id">
                        <div class=" p-4 mb-1" > 
                            <img class="img-fluid" style="padding: 24px;padding-top: 0px;" v-lazy="game.thumbnailUrl" alt="">
                        </div>
                        <!-- <h3 class="mt-4 mb-3"> {{ game.title_ar }}</h3> -->
                        <p class="font-weight-normal mb-4 text-muted"></p>
                        <div class="d-flex mb-4">
                            <div class="col-12" ><a class="btn btn-sm header-btn ml-auto" @click="getFeed(game)" style="width: 100%; color: #fff;">العب الان</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ref } from '@vue/reactivity';
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import TopHome from './HomePage/TopHome.vue'
export default {
  components: { TopHome },
    props: {
        gid: String
    },
   name: 'AppGamesDetails',
   setup(props) {
      const router = useRouter();
      const route = useRoute();
      const gamesDetails = ref([]);

      try {
         HTTP.get(`GamesDetailsById.php?id=`+props.gid).then((res) => {
          gamesDetails.value = res.data.GamesDetails; 
        });
      } catch (err) {
        console.log(err);
      }

      const getFeed = (game) => {
          router.push({ name: "PlayGames", params: { gid: game.gid } });
      };

      return {
        getFeed,
        gamesDetails
      };

    }
}
</script>

<style>

</style>